import React, { useState } from "react"

import { useInView } from 'react-intersection-observer';


import Layout from '../components/Layout'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import loadable from '@loadable/component'

import RedditSvg from '../assets/svg/reddit.svg';
import DiscordSvg from '../assets/svg/discord.svg';
import TwitterSvg from '../assets/svg/twitter.svg';

import blockchainSvg from '../assets/illustratlion/blockchain.svg';
import dragAndDrop_180Svg from '../assets/illustratlion/drag_and_drop_180.svg'
import dragAndDrop_090Svg from '../assets/illustratlion/drag_and_drop_090.svg'

import dashLine090Img from "../assets/images/dash-line-arrow-090.png"
import dashLine180Img from "../assets/images/dash-line-arrow-180.png"



// import blockChainImg from '../assets/images/blockchain_illustration.png'

import { 
  pageLink, 
  demoLink, 
  ioOption, 
} from '../utils'


import './index.scss';

// Static Image source
// const blockchainSvg = '../assets/illustratlion/blockchain.svg';`
// const dragAndDrop_180Svg = '../assets/illustratlion/drag_and_drop_180.svg'
// const dragAndDrop_090Svg = '../assets/illustratlion/drag_and_drop_090.svg'

const blockChainImg = '../assets/images/blockchain_illustration.png'
// const dragAndDrop180Img = "../assets/illustratlion/drag_and_drop_180.png"
// const dragAndDrop090Img = "../assets/illustratlion/drag_and_drop_090.png"

const ModalVideo = loadable(() => import('react-modal-video'))

const highLightBoxData = [
  { 
    title: "From Programmers to Creators", 
    text: "Grow Web3 by decimating the hurdles of creation"
  },
  { 
    title: "Visual Programming Technology", 
    text: "Speed up time-to-market with user-friendly interfaces"
  },
  {
    title: "A Vibrant Community", 
    text: "Share and remix recipes for smart contracts"
  },
]


const CtaBoxData = [
  {
    title: "INCLUDE Community", 
    text: "Get the latest news and updates via our channels including Discord, Telegram, Twitter and more.", 
    buttonText: "STAY CONNECTED", 
    toLink: pageLink.community,
  },
  { 
    title: "Creator and Developer Resources", 
    text: "Documentation, protocol specification, demos, tutorials and more to get you up to speed.", 
    buttonText: "START BUILDING", 
    hrefLink: pageLink.contactForm,
    hrefTargetNew: true,
  },
  { 
    title: "About INCLUDE", 
    text: "Get to know more about us through our mission, vision and values.", 
    buttonText: "LEARN MORE", 
    toLink: pageLink.about,
  },
]

const DataBox = ({boxTitle, boxText, boxButtonText, hrefLink, toLink, hrefTargetNew}) => {
  const [ refDataBox, inViewDataBox, entryDataBox ] = useInView(ioOption);

  return (
    <div ref={refDataBox} className={`box ${fadeIn(inViewDataBox)}`}>
      <h2 className="box__title">{ boxTitle }</h2>
      <p className="box__text">{ boxText }</p>
      { boxButtonText && hrefLink &&  
        <a 
          className="button button-100" 
          href={hrefLink}
          target={hrefTargetNew && "_blank"}
        >
          { boxButtonText }
        </a>}
      { boxButtonText && toLink && 
        <Link className="button button-100" to={ toLink }>{ boxButtonText }</Link> }
    </div>
  )
}

const fadeIn = (InViewEl) => InViewEl ? "opacity-100" : "opacity-000"  



// markup
const IndexPage = () => {
  const [ refHeroContent, inViewHeroContent, entryHeroContent ] = useInView(ioOption);
  const [ refHeroImg, inViewHeroImg, entryHeroImg ] = useInView(ioOption);
  const [ refHeroMedia, inViewHeroMedia, entryHeroMedia ] = useInView(ioOption);
  // const [ refDemocImg, inViewDemocImg, entryDemocImg ] = useInView(ioOption);
  // const [ refDemocImgMobile, inViewDemocImgMobile, entryDemocImgMobile ] = useInView(ioOption);
  const [ refDeomcContent, inViewDemocContent, entryDemocContent ] = useInView(ioOption);
  const [ refDeomcBottom, inViewDemocBottom, entryDemocBottom ] = useInView(ioOption);
  const [ refHighlight, inViewHighlight ] = useInView(ioOption);



  const [isOpen, setOpen] = useState(false)

  return (
    <Layout
      title="Include | Home"
      pageClassName="page-home"
    >      
      <section className="page-home__section-hero">
        <div className="page-home__section-hero__container">
          <div className="page-home__section-hero__container__wrapper">
            <div 
              ref={refHeroContent}
              className={`
                page-home__section-hero__container__wrapper__left 
                ${fadeIn(inViewHeroContent)}
              `}
            >
              <h1 className="page-title">The world’s first universal language of blockchains</h1>
              <div className="button-group">
                <a className="button button-100" href={pageLink.contactForm} target="_blank">START BUILDING</a>
                <a className="button button-100" href={pageLink.whitepaper} target="_blank">READ WHITEPAPER</a>
              </div>
            </div>
            <div className={`${fadeIn(inViewHeroImg)}`} ref={refHeroImg}>
              <StaticImage 
                className={`page-home__section-hero__container__wrapper__right section-img`}
                src={ blockChainImg } 
                objectFit="contain"
                layout="fullWidth"
                alt="" 
              />
            </div>

            {/* <img 
              ref={refHeroImg}
              src={ blockchainSvg } 
              alt="" 
              className={`
                page-home__section-hero__container__wrapper__right section-img
                ${fadeIn(inViewHeroImg)}
              `}   
            /> */}
          </div>
          
          <div 
            ref={refHeroMedia} 
            className={`social-media-group opacity-000 ${inViewHeroMedia ? "opacity-100" : ""}`}
          >
            <a className="icon-link twitter" href="/" aria-label="Twitter"><TwitterSvg /></a>
            <a className="icon-link discord" href="/" aria-label="Discord"><DiscordSvg /></a>
            {/* <a className="icon-link reddit" href="/" aria-label="Reddit"><RedditSvg /></a> */}
          </div>
        </div>
      </section>

      

      <section className={`page-home__section-democ`}>
        <div className="page-home__section-democ__container">
          <div 
            ref={refDeomcContent} 
            className={`
              page-home__section-democ__container__content 
              ${fadeIn(inViewDemocContent)}
            `}
          >
            <h1 className="section-title">Democratizing Web3, DeFi and Dapp Creation</h1>
            <p className="section-title-tag">Include is a low-code platform on a mission to democratize blockchain wealth creation by empowering millions of creators serving billions of consumers.</p>
            <a className="button button-100" href={pageLink.contactForm} target="_blank">BECOME A CREATOR</a>
          </div>

        </div>
      </section>

      <section className="page-home__section-empower">
          <div className="page-home__section-empower__container">
            <div 
              ref={refDeomcBottom}
              className={`
                page-home__section-empower__container__content
                ${fadeIn(inViewDemocBottom)}
              `}>
              <h1 className={`title`}>Empowering Everyone in a Multi-Chain Future</h1>
              {/* <p className="title-tag">Transform visual projects into multi-chain smart contracts</p> */}
              {/* uncomment to show video */}
              {/* <ModalVideo 
                channel='youtube' 
                autoplay 
                isOpen={isOpen} 
                videoId={demoLink} 
                onClose={() => setOpen(false)} 
              /> */}
              <button className="button button-100" onClick={()=> setOpen(true)}>WATCH DEMO</button>
            </div>
          </div>
      </section>

      <section className="page-home__section-highlights">
        <div className="page-home__section-highlights__container">
          <div className="page-home__section-highlights__container__boxes" ref={ refHighlight }>
            <div className={`direction-line-180 direction-line ${fadeIn(inViewHighlight)}`}>
              <img src={dashLine180Img} alt="" />
            </div>
            <div className="boxes-wrapper">
              { highLightBoxData.map(el => (
                <DataBox key={ el.title } boxTitle={ el.title } boxText={ el.text } />
              ))}
            </div>
            <div className={`direction-line-090 direction-line ${fadeIn(inViewHighlight)}`}>
              <img className="horizontal" src={dashLine090Img} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="page-home__section-cta">
        <div className={`page-home__section-cta__container`}>
          { CtaBoxData.map(el => (
            <DataBox 
              key={ el.title } 
              boxTitle={ el.title } 
              boxText={ el.text } 
              boxButtonText={ el.buttonText } 
              hrefLink={ el.hrefLink} 
              toLink={ el.toLink }
              hrefTargetNew={ el.hrefTargetNew } 
            />
          ))}
        </div>      
      </section>


    </Layout>
  )
}

export default IndexPage
